export enum pickupStatus {
  VALID = 'Valid',
  NEED_VALIDATION = 'Need Validation',
  FICTIVE = 'Fiktif',
}

export enum SortFields {
  COURIER_NAME = 'courier_name',
  COURIER_PHONE_NUMBER = 'courier_phone_number',
  CREATED_AT = 'created_at',
}

export enum SortType {
  COURIER_NAME_ASC = 'courier_name-asc',
  COURIER_NAME_DESC = 'courier_name-desc',
  COURIER_PHONE_NUMBER_ASC = 'courier_phone_number-asc',
  COURIER_PHONE_NUMBER_DESC = 'courier_phone_number-desc',
  CREATED_AT_ASC = 'created_at-asc',
  CREATED_AT_DESC = 'created_at-desc',
}

export enum EnumValidation {
  valid = '1',
  fictive = '2',
  empty = '0',
}
