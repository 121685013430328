import Vue from 'vue'
import { container } from 'tsyringe'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { Pagination } from '@/domain/entities/Pagination'
import {
  HistoryLog,
  PickupRadiusDetail,
  PickupRadiusValidation,
  PickupRadiusValidationDetail,
  PickupRadiusValidations,
  Summary,
} from '@/domain/entities/PickupRadiusValidation'
import { PickupRadiusValidationPresenter } from '@/app/ui/presenters/PickupRadiusValidationPresenter'
import { Utils } from '@/app/infrastructures/misc'
import { UpdatePickupLogStatusRequest } from '@/data/payload/api/PickupRadiusValidationRequest'
import {
  IPickupRadiusValidationDetailParams,
  IPickupRadiusValidationListParams,
} from '@/data/infrastructures/misc/interfaces/pickupRadiusValidation'

export interface PickupRadiusValidationState {
  isLoading?: boolean
  isLoadingExport?: boolean
  isUpdateStatusLoading?: boolean
  isUpdateStatusSuccess?: boolean
  paginationData?: Pagination
  pickupRadiusValidationData?: Array<PickupRadiusValidation>
  pickupRadiusValidationSummaryData?: Summary
  pickupRadiusValidationDetailData?: PickupRadiusValidation
  pickupHistoryLogData?: Array<HistoryLog>
  pickupHistoryLogPaginationData?: Pagination
}

export interface UpdatePickupLogStatusPayload {
  pickupLogId: number
  status: number
  description: string
}

@Module({
  namespaced: true,
  store,
  name: 'pickup-radius-validation',
  dynamic: true,
})
class PickupRadiusValidationController extends VuexModule
  implements PickupRadiusValidationState {
  private presenter: PickupRadiusValidationPresenter = container.resolve(
    PickupRadiusValidationPresenter
  )
  isLoading = false
  isLoadingExport = false
  isUpdateStatusLoading = false
  isUpdateStatusSuccess = false
  paginationData: Pagination = new Pagination(1, 50, 0)
  pickupRadiusValidationData: Array<PickupRadiusValidation> = [
    new PickupRadiusValidation(),
  ]
  pickupRadiusValidationSummaryData: Summary = new Summary()
  pickupRadiusValidationDetailData: PickupRadiusValidationDetail = new PickupRadiusValidationDetail()
  pickupHistoryLogData: Array<HistoryLog> = [new HistoryLog()]
  pickupHistoryLogPaginationData: Pagination = new Pagination(1, 5, 0)

  @Action({ rawError: true })
  public getPickupRadiusValidations(
    params: IPickupRadiusValidationListParams
  ): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getPickupRadiusValidations(formattedParams)
      .then(res => {
        this.setPickupRadiusValidationData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Location List Error',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPickupRadiusValidationDetail(payload: {
    pickupLogId: number
    params: IPickupRadiusValidationDetailParams
  }): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(payload.params),
      'snake_case'
    )

    this.presenter
      .getPickupRadiusValidationDetail(payload.pickupLogId, formattedParams)
      .then(res => {
        this.setPickupRadiusValidationDetailData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Pickup Location Detail Error',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updatePickupLogStatus(payload: UpdatePickupLogStatusPayload): void {
    this.setUpdateStatusLoading(true)

    this.presenter
      .updatePickupRadiusValidation(
        payload.pickupLogId,
        new UpdatePickupLogStatusRequest(payload.status, payload.description)
      )
      .then(res => {
        this.setIsUpdateStatusSuccess(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Pickup Log Status Error',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setIsUpdateStatusSuccess(false)
      })
      .finally(() => {
        this.setUpdateStatusLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportPickupLog(params: IPickupRadiusValidationListParams): void {
    this.setExportLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .exportPickupLog(formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Pickup Log Error',
          text: [400, 422].includes(error?.status)
            ? error?.error?.message?.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setExportLoading(false)
      })
  }

  @Mutation
  private setLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @Mutation
  private setExportLoading(isLoading: boolean): void {
    this.isLoadingExport = isLoading
  }

  @Mutation
  private setUpdateStatusLoading(isLoading: boolean): void {
    this.isUpdateStatusLoading = isLoading
  }

  @Mutation
  public setIsUpdateStatusSuccess(isSuccess: boolean): void {
    this.isUpdateStatusSuccess = isSuccess
  }

  @Mutation
  private setPickupRadiusValidationData(data: PickupRadiusValidations): void {
    this.pickupRadiusValidationData = data.data
    this.paginationData = data.pagination
    this.pickupRadiusValidationSummaryData = data.summary
  }

  @Mutation
  private setPickupRadiusValidationDetailData(data: PickupRadiusDetail): void {
    this.pickupRadiusValidationDetailData = data.pickupValidationRadius
    this.pickupHistoryLogData = data.historyLogs.data
    this.pickupHistoryLogPaginationData = data.historyLogs.pagination
  }
}

export default getModule(PickupRadiusValidationController)
