import { UpdatePickupLogStatusRequestInterface } from '../contracts/PickupRadiusValidationRequest'

export class UpdatePickupLogStatusRequest
  implements UpdatePickupLogStatusRequestInterface {
  private status?: number
  private description?: string

  constructor(status?: number, description?: string) {
    this.status = status
    this.description = description
  }

  public toPayload(): string {
    return JSON.stringify({
      status: this.status,
      description: this.description,
    })
  }
}
